import { type FormatXMLElementFn } from 'intl-messageformat'
import { type JSX, type ReactNode } from 'react'

/**
 * Represent italic branded color text, mostly used in headers.
 * Figma design https://www.figma.com/file/bnhEZxjbetUikQLkV6K3yT/RC-Fonts?type=design&node-id=216-120&mode=design&t=aphPOSTmnyYQ14S8-4.
 */
const highlight = (text: ReactNode[]): JSX.Element => (
  <span className="font-medium italic text-dark-accent" key="highlight">
    {text}
  </span>
)

export const customElements: Record<string, FormatXMLElementFn<ReactNode>> = {
  highlight,
}
