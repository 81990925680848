'use client'

import { type FC, useEffect } from 'react'
import useImmutableSwr from 'swr/immutable'

import { apmCaptureError, apmSetCustomContext } from '../domain/apm'
import {
  ErrorPage,
  ErrorPageError,
  errorPageGetPropsAction,
} from '../domain/error-page'
import { AppLayout } from '../domain/next-compose'

import '../styles/globals.css'

const GLOBAL_ERROR_PAGE_TITLE = 'Internal Server Error'

type GlobalErrorPageProps = {
  error: Error & { digest?: string }
}

const GlobalErrorPage: FC<GlobalErrorPageProps> = ({ error }) => {
  useEffect(() => {
    apmSetCustomContext({
      digest: error.digest,
      router: 'app',
    })
    apmCaptureError(new ErrorPageError(error))
  }, [error])

  const { data, error: actionError } = useImmutableSwr<
    Awaited<ReturnType<typeof errorPageGetPropsAction>>,
    Error
  >('global-error', async () => errorPageGetPropsAction(import.meta.url))

  let children = null

  if (data) {
    children = (
      <AppLayout {...data.appLayoutProps}>
        <ErrorPage standardLayoutData={data.standardLayoutData} />
      </AppLayout>
    )
  }

  if (actionError) {
    children = (
      <div className="container py-4">
        <h1 className="text-center text-4xl">{GLOBAL_ERROR_PAGE_TITLE}</h1>
      </div>
    )
  }

  useEffect(() => {
    if (actionError) {
      apmCaptureError(actionError)
    }
  }, [actionError])

  return (
    <html lang={data?.appLayoutProps.publicGlobalConfig.locale ?? 'en'}>
      <head>
        <title>{GLOBAL_ERROR_PAGE_TITLE}</title>
        <meta
          content="width=device-width, initial-scale=1, maximum-scale=1"
          name="viewport"
        />
      </head>
      <body>{children}</body>
    </html>
  )
}

export default GlobalErrorPage
